import React, { useState }  from "react";
import * as Sentry from '@sentry/react';
import { AiFillBug,AiOutlineClose  } from 'react-icons/ai';
import Btn from "./Btn";

export default function Footer(){
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [feedback, setFeedback] = useState('');

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleFeedbackSubmit = (event) => {
        event.preventDefault();
        const err = new Error('User feedback');
        Sentry.withScope((scope) => {
            scope.setExtras({ feedback });
            Sentry.captureException(err);
        });
        setShowFeedbackForm(false);
        setFeedback('');
    };

    return (
        <footer className="text-muted py-5 footer px-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 py-md-3">
                        <h5 className="titre-footer py-2">La solution</h5>
                        <ul className="nav flex-column gap-2">
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/#video" className="nav-link p-0 text-muted">Comment ça marche ?</a></li>
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/#solution_tarif" className="nav-link p-0 text-muted">Pourquoi nous choisir ?</a></li>
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/#tarifs" className="nav-link p-0 text-muted">Tarifs</a></li>
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/#fonctionnalites" className="nav-link p-0 text-muted">Nos fonctionnalités innovantes</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 py-md-3">
                        <h5 className="titre-footer py-2">Support</h5>
                        <ul className="nav flex-column gap-2">
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/#faq" className="nav-link p-0 text-muted">Foire aux questions</a></li>
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/contact" className="nav-link p-0 text-muted">Contactez-nous</a></li>
                            <li className="nav-item mb-2"><a href="/inscription" className="nav-link p-0 text-muted">Inscription</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 py-md-3">
                        <h5 className="titre-footer py-2">L'entreprise</h5>
                        <ul className="nav flex-column gap-2">
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/#quisommesnous" className="nav-link p-0 text-muted">Qui
                                sommes-nous ?</a></li>
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/logo" className="nav-link p-0 text-muted">Téléchargez le logo pour vos factures</a></li>
                            {/*<li className="nav-item mb-2"><a href="/blog/roadmap" className="nav-link p-0 text-muted">Roadmap</a></li>
                            <li className="nav-item mb-2"><a href="/blog" className="nav-link p-0 text-muted">Actualités</a></li>*/}
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 py-md-3">
                        <h5 className="titre-footer py-2">Légal</h5>
                        <ul className="nav flex-column gap-2">
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/cgv" className="nav-link p-0 text-muted">CGV</a></li>
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/cgu" className="nav-link p-0 text-muted">CGU</a></li>
                            <li className="nav-item mb-2"><a href="https://facturesimpayees.com/politique-vie-privee" className="nav-link p-0 text-muted">Politique de vie privée</a></li>
                        </ul>
                    </div>
                </div>
                <div className="d-flex flex-md-row pt-4 flex-column">
                    <div className="">
                        <div className="d-flex flex-row align-items-center">
                            <a href={"/"}><img src={"/assets/img/logo.png"} alt={"Logo factures impayées"} width={44} height={60}/></a>
                            <a href={"/"} className={"text-muted text-decoration-none"}><div className="texte-logo-footer px-1">facturesimpayees.com</div></a>
                        </div>
                    </div>
                    {/*<div className="d-flex flex-row align-items-center reseaux-sociaux px-md-4 px-0 gap-3 py-2 py-md-0">
                        <div className="separateur-footer d-md-flex d-none"></div>
                        <a href="/" className={"icon-social"}><img alt={"Facebook"} src={"/assets/img/facebook.png"} /></a>
                        <a href="/" className={"icon-social"}><img alt={"Twitter"} src={"/assets/img/twitter.png"} /></a>
                        <a href="/" className={"icon-social"}><img alt={"Linkedin"} src={"/assets/img/linkedin.png"} /></a>
                    </div>*/}
                </div>
                <div className="copyright">Copyright © 2023 factureimpayees.com</div>
            </div>
            {/*<button
                style={{
                    position: 'fixed',
                    bottom: '10px',
                    right: '10px',
                    background: 'transparent',
                    border: '1px solid #CCC',
                    cursor: 'pointer',
                    display: 'flex',
                    backgroundColor: 'white',
                    gap: '10px',
                    padding: '10px',
                }}
                onClick={() => setShowFeedbackForm(true)}
            >
                <AiFillBug size={30} color="black" /><div>Reporter un bug</div>
            </button>*/}
            {showFeedbackForm &&
                <div style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    width: '500px',
                    boxShadow: '0 0 40px rgba(0,0,0,0.2)',
                }}>
                    <button
                        onClick={() => setShowFeedbackForm(false)}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        <AiOutlineClose size={30} color="black" />
                    </button>
                    <h2>Reporter un bug</h2>
                    <form onSubmit={handleFeedbackSubmit} style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <textarea
                        onChange={handleFeedbackChange}
                        value={feedback}
                        required
                        style={{width: '100%', minHeight: '100px', padding: '10px'}}
                        placeholder="Décrivez le problème rencontré avec un maximum de détails"
                    />
                        <Btn type="submit" style={{padding: '10px', cursor: 'pointer'}}>Envoyer</Btn>
                    </form>
                </div>}
        </footer>
    );
}
