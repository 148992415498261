import React, {useEffect, useState} from "react";
import {Helmet} from 'react-helmet';
import DataTable from 'react-data-table-component';
import {ButtonGroup, Button} from "react-bootstrap";
import {DebounceInput} from "react-debounce-input";
import {

    getTJ, mesDossiersAvocat,
    terminerDossierAvocat
} from "../../class/Requete";
import {getUserFromUid} from "../../class/User";
import {closeModal, Modal, openModal} from "../../components/Modal";
import {getElement} from "bootstrap/js/src/util";
import Btn from "../../components/Btn";
import {urlSite} from "../../class/Config";

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};


const columns = [
    {
        name: 'Identifiant',
        selector: row => row.id,
        sortable: true,
    },
    {
        name: 'Créancier',
        selector: row => row.RaisonSociale,
        sortable: true,
    },
    {
        name: 'Débiteur',
        selector: row => row.type === 'entreprise' ? row.raisonSocialeDebiteur : row.PrenomDebiteur + ' ' + row.NomDebiteur,
        sortable: true,
    },
    {
        name: 'Tribunal',
        selector: row => row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleGreffe : row.Tribunal,
        sortable: true,
    },
    {
        name: 'Montant de la créance',
        selector: row => row.totalRestant.toFixed(2) + ' €',
        sortable: true,
    },
    {
        name: 'Email créancier',
        selector: row => row.emailCreancier,
        sortable: true,
    },
    {
        name: 'Statut',
        selector: row => capitalizeFirstLetter(row.statut_avocat),
        sortable: true,
    },
    {
        name: 'Actions',
        cell: row => (
                <><ButtonGroup>
                    <Button title={"Voir le dossier"} variant="primary" onClick={() => {
                        openModal(document.getElementById("modal_voir_proposition_" + row.id));
                    }}>
                        <img src="/assets/img/eye.svg" alt="Voir" width="25" height="25"/>
                    </Button>
                    {row.statut_avocat!=="recouvrement termine"?<Button title={"Archiver"} variant="success" onClick={() => {
                        openModal(document.getElementById("modal_termine_" + row.id));
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22" fill="none">
                            <path
                                d="M23.748 1.42875C23.9101 1.26337 24.1035 1.13198 24.317 1.04229C24.5305 0.952601 24.7598 0.906403 24.9913 0.906403C25.2229 0.906403 25.4521 0.952601 25.6656 1.04229C25.8791 1.13198 26.0726 1.26337 26.2346 1.42875C26.9139 2.11512 26.9234 3.22425 26.2584 3.9225L12.215 20.5237C12.0556 20.6988 11.8621 20.8396 11.6464 20.9373C11.4307 21.035 11.1973 21.0877 10.9606 21.0922C10.7238 21.0966 10.4886 21.0527 10.2694 20.9631C10.0502 20.8736 9.85154 20.7402 9.68564 20.5712L1.14039 11.912C0.810827 11.5759 0.626221 11.124 0.626221 10.6532C0.626221 10.1825 0.810827 9.73059 1.14039 9.3945C1.30247 9.22912 1.49592 9.09773 1.70941 9.00804C1.9229 8.91835 2.15214 8.87215 2.3837 8.87215C2.61527 8.87215 2.8445 8.91835 3.05799 9.00804C3.27148 9.09773 3.46493 9.22912 3.62701 9.3945L10.8755 16.7404L23.7005 1.481L23.748 1.42875Z"
                                fill="white"/>
                        </svg>
                    </Button>:null}
                </ButtonGroup>
                    <Modal id={"modal_voir_proposition_" + row.id}
                           titre={"Dossier : " + (row.type === 'entreprise' ? row.raisonSocialeDebiteur : row.PrenomDebiteur + ' ' + row.NomDebiteur)}
                           body={<div>
                               <ul>
                                   <li>Identifiant du dossier : {row.id}</li>
                                   <li>Créancier
                                       : {row.RaisonSociale}</li>
                                   <li>Forme juridique du créancier : {row.formeJuridiqueCreancier}</li>
                                   <li>Débiteur
                                       : {row.type === 'entreprise' ? row.raisonSocialeDebiteur : row.PrenomDebiteur + ' ' + row.NomDebiteur}</li>
                                   <li>Forme juridique du débiteur
                                       : {row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleFormeJuridique : capitalizeFirstLetter(row.type)}</li>
                                   <li>Tribunal délivrant l’injonction de payer
                                       : {row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleGreffe : row.Tribunal}</li>
                                   <li>Date de la demande : {formatDate(row.dateCreation._seconds)}</li>
                               </ul>
                               <strong>Montant de la créance : {row.totalRestant.toFixed(2)} €</strong>
                               <p>Informations de contact du créancier :</p>
                               <ul>
                                   <li>Email : {row.emailCreancier}</li>
                                   <li>Téléphone : {row.telephoneCreancier}</li>
                                   <li>Adresse : {row.adresseCreancier}</li>
                               </ul>
                               {/*<div className={"d-flex justify-content-center"}>
                                   <Btn iconeDroite={"upload"} texte={"Télécharger l'injonction"}
                                        className={"btn-vert "} onClick={async () => {
                                       window.open(row.ordonnance.url);
                                   }}/>
                               </div>*/}

                           </div>}>
                    </Modal>

                    <Modal id={"modal_termine_" + row.id}
                           titre={"Valider la mise en recouvrement du dossier "}
                           body={<div>
                               <p>Confirmez-vous que ce dossier est terminé ?</p>
                               <ul>
                                   <li>Identifiant du dossier : {row.id}</li>
                                   <li>Créancier : {row.RaisonSociale}</li>
                               </ul>
                               <strong>Montant de la créance : {row.totalRestant.toFixed(2)} €</strong>

                           </div>}>
                        <Btn texte="Confirmer" className={"btn-vert "} onClick={async () => {
                            await terminerDossierAvocat(row.id);
                            closeModal(document.getElementById("modal_termine_" + row.id));
                            document.location.reload();
                        }}/>
                        <Btn texte="Annuler" className={"btn-vert "} onClick={async () => {
                            closeModal(document.getElementById("modal_termine_" + row.id));
                        }}/>
                    </Modal>
                </>
        ),
    },
];

const customStyles = {
    table: {
        style: {
            padding: 0,
            margin: 0,
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            font: 'bold 1.7em Inter',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            font: 'normal 1.3em Inter',
        },
    },
};

export default function MesDossiersAvocat() {
    const [filterText, setFilterText] = useState('');
    const [pending, setPending] = useState(true);
    const [propositions, setPropositions] = useState([]);

    const fetchData = async () => {
        let data = await mesDossiersAvocat();
        if(data.message==="Pas de dossier"){
            data=[];
        }else {
            data = await Promise.all(data.map(async (element) => {
                if (element.type !== 'entreprise') {
                    let tj = await getTJ(element.CodePostalDebiteur);
                    element.Tribunal = tj.ville;
                }
                return element;

            }));
        }

        setPropositions(data);
        setPending(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (filterText !== "") {
            const filteredData = propositions.filter(
                item => {
                    const lowerCaseFilter = filterText.toLowerCase();
                    return (
                        (item.raisonSocialeDebiteur && item.raisonSocialeDebiteur.toLowerCase().includes(lowerCaseFilter)) ||
                        (item.PrenomDebiteur && item.PrenomDebiteur.toLowerCase().includes(lowerCaseFilter)) ||
                        (item.NomDebiteur && item.NomDebiteur.toLowerCase().includes(lowerCaseFilter)) ||
                        (item.VilleDebiteur && item.VilleDebiteur.toLowerCase().includes(lowerCaseFilter)) ||
                        (item.totalRestant && item.totalRestant.toString().includes(filterText)) ||
                        (item.id && item.id.toLowerCase().includes(lowerCaseFilter)) ||
                        (item.RaisonSociale && item.RaisonSociale.toLowerCase().includes(lowerCaseFilter)) ||
                        (item.Tribunal && item.Tribunal.toLowerCase().includes(lowerCaseFilter)) ||
                        (item.emailCreancier && item.emailCreancier.toLowerCase().includes(lowerCaseFilter))
                    );
                }
            );
            setPropositions(filteredData);
        } else {
            fetchData();
        }
    }, [filterText]);

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <div className={"texte-standard input-search-tabs"}>
                Rechercher :&nbsp;
                <DebounceInput
                    debounceTimeout={1000}
                    className={""}
                    type={"search"}
                    onChange={e => setFilterText(e.target.value)}
                    value={filterText}
                />
            </div>
        );
    }, [filterText]);

    return (
        <div className="container-fluid justify-content-start align-items-center d-flex flex-column">
            <Helmet>
                <title>Commissaire de justice - Propositions</title>
                <meta name="description"
                      content="Retrouvez ici toutes les propositions de dossiers que vous avez reçues sur facturesimpayees.com"/>
                <meta name="keywords" content="commissaire de justice, propositions"/>
            </Helmet>
            <div className={"justify-content-start align-items-start w-100"}>
                <h2>Mes dossiers</h2>
                <DataTable
                    columns={columns}
                    data={propositions}
                    defaultSortFieldId={1}
                    customStyles={customStyles}
                    pagination
                    progressPending={pending}
                    progressComponent={<div className="spinner-border" role="status"></div>}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    noDataComponent={"Aucune proposition en attente"}
                />
            </div>
        </div>
    );
}
