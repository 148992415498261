import React, {useEffect, useState} from "react";
import Btn from "../../components/Btn";
import {createRequete, getRequeteCountFromUserId, setRequete, updateRequete} from "../../class/Requete";
import {getCurrentUser, getUserFromUid, isAbonne, requeteGratuiteAvecMemeSiren, updateUser} from "../../class/User";
import {closeModal, Modal, openModal} from "../../components/Modal";
import {Helmet} from 'react-helmet';
import {stripeIsAbonnePersonnalise} from "../../class/Abonnement";
import ModalNouvelleRequete from "../../components/ModalNouvelleRequete";

export default function NouvelleRequete(props) {

    const [totaux, setTotaux] = useState({});


    useEffect(() => {
        const getData = async () => {
            let user = await getCurrentUser();
            let result = await getRequeteCountFromUserId(user.uid);
            setTotaux(result);
        }
        getData();
    }, []);


    return (
        <div className={"container-fluid justify-content-center align-items-center d-flex flex-column"}>
            <Helmet>
                <title>Démarrez une demande de paiement sur facturesimpayees.com</title>
                <meta name="description"
                      content="Initiatez une demande de paiement sur facturesimpayees.com pour recevoir rapidement le paiement de vos factures impayées. Utilisez notre formulaire pour soumettre votre requête et suivez l'évolution de votre demande."/>
                <meta name="keywords"
                      content="demande de paiement, factures impayées, plateforme, formulaire, requête, suivi, facturesimpayees.com"/>
            </Helmet>
            <div className="row gx-lg-8 gx-xl-12 gy-10 gy-xl-0 mb-14 align-items-center">
                <div className="col-lg-7 order-lg-2">
                    <figure><img className="img-auto" src="/assets/img/start.svg" alt=""/></figure>
                </div>
                <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
                    {totaux.en_cours > 0 ?
                        <h1 className="display-1 fs-54 mb-5 mx-md-n5 mx-lg-0 mt-7">Constituer un dossier
                            <br
                                className="d-md-none"/> <br className="d-md-none"/><span
                                className="rotator-fade text-violet"> supplémentaire</span></h1> :
                        <h1 className="display-1 fs-54 mb-5 mx-md-n5 mx-lg-0 mt-7">Créez votre premier <br
                            className="d-md-none"/> <br className="d-md-none"/><span
                            className="rotator-fade text-violet">dossier</span></h1>}
                    <p
                        className="lead fs-lg mb-7">Vous
                        avez {totaux.total} {totaux.total === 1 ? "dossier" : "dossiers"} dont <span
                            className={"badge-vert"}>{totaux.en_cours}</span> qui {totaux.en_cours === 1 ? "attend" : "attendent"} une
                        action de votre part. {totaux.action > 0 ?
                            <a href={"/dashboard/requete-en-cours"} className={"text-violet"}>Effectuer les actions
                                necessaires</a> : null}</p>
                    <Btn className={"mb-5"} iconeDroite={"fleche"} texte={"Créer un nouveau dossier"}
                         onClick={createRequete} loading={true}/>
                </div>
            </div>


            <ModalNouvelleRequete/>
        </div>


    );
}
