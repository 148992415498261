import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {PDFDocument, StandardFonts, rgb,degrees} from 'pdf-lib';
import {getFacturesFromRequete, getRequete, getTotal, updateRequete} from "../class/Requete";
import {getCurrentUser, getUserFromUid} from "../class/User";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {firebaseApp} from "../components/Firebase";

export default function PdfRelance() {
    let {id} = useParams();
    const [urlPdf, setUrlPdf] = useState("");
    const convertDateFormat = (date) => {
        // Check for the correct input format
        const re = /^\d{4}-\d{2}-\d{2}$/;
        if (!date.match(re)) {
            throw new Error('Invalid date format. Please use YYYY-MM-DD.');
        }

        // Split the date by hyphen to get the individual parts
        const parts = date.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];

        // Return the date in DD/MM/YYYY format
        return `${day}/${month}/${year}`;
    }

    function drawRightAlignedText(page, text, y, font, size, pageWidth, margin) {
        const textWidth = font.widthOfTextAtSize(text, size);
        const x = pageWidth - textWidth - margin; // margin est la marge droite que vous souhaitez conserver
        page.drawText(text, {
            x: x,
            y: y,
            size: size,
            font: font,
            color: rgb(0, 0, 0),
        });
    }


    useEffect(() => {
        async function fetchData() {
            let user = await getCurrentUser();
            const [requete, societe, total, factures] = await Promise.all([
                getRequete(id),
                getUserFromUid(user.uid),
                getTotal(id),
                getFacturesFromRequete(id),
            ]);
            return {requete, user, societe, total, factures};
        }

        async function setupPdfDocument() {
            const pdfDoc = await PDFDocument.create();
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
            const helveticaFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
            const helveticaFontOblique = await pdfDoc.embedFont(StandardFonts.HelveticaOblique);
            return {pdfDoc, helveticaFont, helveticaFontBold, helveticaFontOblique};
        }


        async function createPdf() {
            const {requete,  societe, total, factures} = await fetchData();
            const {pdfDoc, helveticaFont, helveticaFontBold, helveticaFontOblique} = await setupPdfDocument();

            let page = pdfDoc.addPage()

            function drawTextPage(page, text, options) {
                const lines = text.split('\n');
                let currentY = options.y;

                for (let line of lines) {
                    let result = addNewPageIfNeeded(page, currentY, pdfDoc);
                    page = result.page;
                    currentY = result.top;

                    page.drawText(line, {
                        x: options.x,
                        y: currentY,
                        size: options.size,
                        font: options.font,
                        color: options.color,
                    });

                    currentY -= options.lineHeight; // Décaler la position Y pour la ligne suivante
                }
            }

            let top = 780;


            /*if(requete.RaisonSociale_crediteur) {
                societe.RaisonSociale=requete.RaisonSociale_crediteur;
            }*/

            page.drawText(societe.RaisonSociale, {
                x: 50,
                y: top,
                size: 10,
                font: helveticaFontBold,
                color: rgb(0, 0, 0),
            })

            /*if(requete.Adresse_crediteur) {
                societe.Adresse=requete.Adresse_crediteur;
            }*/

            page.drawText(societe.Adresse, {
                x: 50,
                y: top - 15,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            /*if(requete.CodePostal_crediteur) {
                societe.CodePostal=requete.CodePostal_crediteur;
            }

            if(requete.Ville_crediteur) {
                societe.Ville=requete.Ville_crediteur;
            }*/

            page.drawText(societe.CodePostal + " " + societe.Ville, {
                x: 50,
                y: top - 30,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            /*if(requete.SIREN_crediteur) {
                societe.SIREN=requete.SIREN_crediteur;
            }*/

            page.drawText("SIREN : " + societe.SIREN, {
                x: 50,
                y: top - 45,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            if(societe.Type!=="Autre") {
                if(societe.Type) {
                    page.drawText(societe.Type, {
                        x: 50,
                        y: top - 60,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }
            }


            drawRightAlignedText(page, societe.Ville + ", le " + (new Date().toLocaleDateString()), 700, helveticaFont, 10, page.getSize().width, 50);


            const text = "Objet :";
            const textSize = 10;
            const textX = 50;
            const textY = top - 130;
            const textWidth = helveticaFontOblique.widthOfTextAtSize(text, textSize);
            const underlineY = textY - 2; // Légèrement sous le texte, ajustez selon les besoins
            const underlineThickness = 1; // Épaisseur de la ligne de soulignement


            drawTextPage(page, text, {
                x: textX,
                y: textY,
                size: textSize,
                font: helveticaFontOblique,
                color: rgb(0, 0, 0)
            });

            page.drawLine({
                start: {x: textX, y: underlineY},
                end: {x: textX + textWidth, y: underlineY},
                thickness: underlineThickness,
                color: rgb(0, 0, 0),
            });

            drawTextPage(page, "rappel facturation", {
                x: textX + 35,
                y: textY,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });

            drawTextPage(page, "Envoi en LRAR à :", {
                x: 50,
                y: top - 150,
                size: 10,
                font: helveticaFontBold,
                color: rgb(0, 0, 0),
            });


            if (requete.type === "entreprise") {
                drawTextPage(page, requete.ficheIdentiteDebiteur.Data.Denomination, {
                    x: 280,
                    y: top - 180,
                    size: 10,
                    font: helveticaFontBold,
                    color: rgb(0, 0, 0),
                })
            }
            if (requete.type === "particulier") {
                drawTextPage(page, requete.TitreDebiteur + " " + requete.PrenomDebiteur + " " + requete.NomDebiteur, {
                    x: 280,
                    y: top - 180,
                    size: 10,
                    font: helveticaFontBold,
                    color: rgb(0, 0, 0),
                })
            }
            /**
             * Si l'adresse est trop longue spliter sur deux lignes
             */


            let ecartAdresse = 0;

            const maxChars = 40;
            let adresse1 = '';
            let adresse2 = '';
            if (requete.AdresseDebiteur) {
                const words = requete.AdresseDebiteur.split(' ');
                for (let word of words) {
                    if ((adresse1 + word).length <= maxChars) {
                        adresse1 += word + ' ';
                    } else {
                        adresse2 += word + ' ';
                    }
                }


// Enlève l'espace supplémentaire à la fin
                adresse1 = adresse1.trim();
                adresse2 = adresse2.trim();
            }

            if (adresse2 !== "") {
                drawTextPage(page, adresse1, {
                    x: 280,
                    y: top - 195,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
                drawTextPage(page, adresse2, {
                    x: 280,
                    y: top - 210,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
                ecartAdresse = 15;
            } else {
                if (requete.AdresseDebiteur) {
                    drawTextPage(page, requete.AdresseDebiteur, {
                        x: 280,
                        y: top - 195,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    });
                }
            }


            drawTextPage(page, requete.CodePostalDebiteur + " " + requete.VilleDebiteur, {
                x: 280,
                y: (top - 210) - ecartAdresse,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            if (requete.type === "entreprise") {
                drawTextPage(page, "SIREN : " + requete.SIRENDebiteur, {
                    x: 280,
                    y: (top - 225) - ecartAdresse,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
            }


            drawTextPage(page, "Madame, Monsieur,", {
                x: 50,
                y: top - 250,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
            if(requete.RaisonSociale_crediteur) {
                drawTextPage(page, "Nous vous adressons la présente aux intérêts de la société " + requete.RaisonSociale_crediteur + ".", {
                    x: 50,
                    y: top - 270,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
                top-=20;
            }


            if (factures.length === 1) {
                drawTextPage(page, "Nous constatons que la facture suivante demeure en souffrance :", {
                    x: 50,
                    y: top - 270,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
            } else {
                drawTextPage(page, "Nous constatons que les factures suivantes demeurent en souffrance :", {
                    x: 50,
                    y: top - 270,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
            }

            let nextY = top - 260;
            factures.map((facture, i) => {
                let result = addNewPageIfNeeded(page, nextY - 15, pdfDoc);
                page = result.page;
                nextY = result.top;

                drawTextPage(page, "• N°" + facture.invoice_id + " en date du " + convertDateFormat(facture.invoice_date) + " d’un montant de " + Math.round(facture.total_amount * 100) / 100 + " € TTC", {
                    x: 80,
                    y: nextY - 10,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
                //nextY -= 0; // Mise à jour de la position Y pour le prochain élément
                return true;
            })

            //let nextY = (top - 290) - (factures.length * 15) + 0;
            nextY -= 20;


            drawTextPage(page, "Soit un total de " + Math.round(total.totalTTC * 100) / 100 + " € TTC.", {
                x: 80,
                y: nextY - 10,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });

            if (factures.length === 1) {
                drawTextPage(page, "Cette facture n’a jamais fait l’objet d’une quelconque contestation, ni d’un quelconque accord d’échelonnement.", {
                    x: 50,
                    y: nextY - 30,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
            } else {
                drawTextPage(page, "Ces factures n’ont jamais fait l’objet d’une quelconque contestation, ni d’un quelconque accord d’échelonnement.", {
                    x: 50,
                    y: nextY - 30,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
            }
            //nextY = nextY - 60;

            if (total.totalPercu === 0) {
                drawTextPage(page, "À ce jour, aucun règlement n'étant intervenu, vous restez devoir la somme totale de " + total.totalRestant + " €.", {
                    x: 50,
                    y: nextY - 50,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
                nextY = nextY - 50;
            } else {
                let total_reglements = 0;

                factures.map((facture, i) => {
                    if (facture.total_received !== "0") {
                        total_reglements++;
                    }
                    return true;
                })
                if (total_reglements === 1) {
                    drawTextPage(page, "Vous avez procédé au règlement suivant :", {
                        x: 50,
                        y: nextY - 50,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    });
                } else {
                    drawTextPage(page, "Vous avez procédé aux règlements suivants :", {
                        x: 50,
                        y: nextY - 50,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    });
                }
                let nbrReceived = 0;
                nextY -= 50;
                factures.map((facture, i) => {
                    if (facture.total_received !== "0") {
                        let result = addNewPageIfNeeded(page, nextY - 15, pdfDoc);
                        page = result.page;
                        nextY = result.top;
                        drawTextPage(page, "Montant de " + facture.total_received + " € TTC sur la facture N°" + facture.invoice_id, {
                            x: 80,
                            y: nextY,
                            size: 10,
                            font: helveticaFont,
                            color: rgb(0, 0, 0),
                        });
                        nbrReceived++;
                    }
                    return nbrReceived;
                })

                drawTextPage(page, "Soit un total de " + total.totalPercu + " € TTC.", {
                    x: 80,
                    y: (nextY - 15),
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
            }
            nextY-=15;
            drawTextPage(page, "En conséquence, nous vous invitons à procéder à leur règlement sans délai.", {
                x: 50,
                y: nextY - 20,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                lineHeight: 15
            });

            drawTextPage(page, "En cas de difficulté de trésorerie ou d’une autre nature (qualité/quantité des produits et/ou de la prestation facturée,\n" +
                "montant de la facture etc…),  nous vous demandons de nous contacter sans délai pour trouver une solution\namiable et, le cas échéant," +
                "envisager  le recours à un mode alternatif de règlement des différends de type\nmédiation ou autre.", {
                x: 50,
                y: nextY - 40,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                lineHeight: 15
            });



            /*drawTextPage(page, "A défaut de retour ou de paiement dans un délai de huit (8) jours à compter de la réception de la présente,\n" +
                "nous considérerons que la démarche amiable de règlement du différend a échoué et\n" +
                "saisirons les Tribunaux compétents pour faire valoir nos droits et obtenir votre condamnation à\n" +
                "nous régler la somme susvisée, sans préjudice des dommages et intérêts en réparation du\n" +
                "préjudice causé par résistance abusive, ainsi que des frais irrépétibles et des dépens qui\n" +
                "seront mis à votre charge.", {
                x: 50,
                y: nextY - 105,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                lineHeight: 15
            });*/

            if(societe.Type==="Avocat"){
                drawTextPage(page, "Vous pouvez répondre à la présente directement ou par l’intermédiaire de votre conseil habituel.", {
                    x: 50,
                    y: nextY - 110,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                    lineHeight: 15
                });
                nextY-=20;
            }

            drawTextPage(page,
                "Nous restons donc dans l’attente de vous lire dans les meilleurs délais.\n\n" +
                "Veuillez agréer, Madame, Monsieur, nos salutations distinguées. ", {
                x: 50,
                y: nextY - 110,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                lineHeight: 15
            });

            drawTextPage(page, `Pour la Société :`, {
                x: 350,
                y: nextY - 265,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                lineHeight: 15
            });

            /*page.drawLine({
                start: {x: 350, y: nextY - 295,},
                end: {x: 423, y: nextY - 295},
                thickness: 1,
                color: rgb(0, 0, 0),
            });*/

            drawTextPage(page, societe.RaisonSociale, {
                x: 425,
                y: nextY - 265,
                size: 10,
                font: helveticaFontBold,
                color: rgb(0, 0, 0),
                lineHeight: 15
            });

            drawTextPage(page, `Son représentant légal.
            
Signature : `, {
                x: 350,
                y: nextY - 275,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
                lineHeight: 15
            });

            if (requete.signatureMED) {
                const jpgUrl = requete.signatureMED;

                const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
                const jpgImage = await pdfDoc.embedPng(jpgImageBytes)
                const jpgDims = jpgImage.scale(0.2)


                page.drawImage(jpgImage, {
                    x: 350,
                    y: nextY - 350,
                    width: jpgDims.width,
                    height: jpgDims.height
                })
            }
            if(requete.test) {
                page.drawText("SPECIMEN", {
                    x: 100,
                    y: 180,
                    size: 120,
                    font: helveticaFontBold,
                    color: rgb(1, 0, 0),
                    opacity: 0.5,
                    rotate: degrees(45)
                });
            }

            await finalizePdf(pdfDoc, id);
            return true;
        }

        async function finalizePdf(pdfDoc, id) {
            const pdfBytes = await pdfDoc.save();
            await updateRequete(id, {
                nbrPagesMiseEnDemeure: pdfDoc.getPages().length
            });
            await uploadPDF(pdfBytes);


            let blob = new Blob([pdfBytes], {type: "application/pdf"});
            let urlBlob = URL.createObjectURL(blob);
            setUrlPdf(urlBlob);
        }

        function addNewPageIfNeeded(page, top, pdfDoc) {
            const marginBottom = 30; // Marge inférieure, ajustez selon vos besoins
            if (top < marginBottom) {
                page = pdfDoc.addPage();
                top = page.getSize().height - 40; // Réinitialisez la position en haut de la nouvelle page
            }
            return {page, top};
        }

        function uploadPDF(file) {
            const storage = getStorage(firebaseApp);
            const storageRef = ref(storage, 'miseEnDemeure/generate-' + id + '.pdf');
            const uploadTask = uploadBytesResumable(storageRef, file);
            let progress;
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                        default:

                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        console.log('File available at', downloadURL);
                        updateRequete(id, {
                            miseendemeuregenerate: downloadURL
                        })
                    });
                }
            );
        }

        createPdf().then(() => {

        });
    }, [id])
    return (
        <div className={"container-fluid d-flex justify-content-center align-content-center p-0 m-0 flex-column"}>
            <iframe src={urlPdf} className={"iframePdf"} title={"PDF"}/>

        </div>
    );
}
