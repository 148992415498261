import {urlFunction, urlSite} from "./Config";
import {getCurrentUser, getUserFromUid} from "./User";
import {fetchWithAuth} from "./Auth";

const getAbonnement = async (id) => {
    let response = await fetch(urlFunction + "/getAbonnement?id=" + id);
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}

const getAbonnementFromPriceId = async (id) => {
    let response = await fetch(urlFunction + "/getAbonnementFromPriceId?id=" + id);
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}
const stripeDirectGetAbonnement = async (subscription_id) => {
    let response = await fetch(urlFunction + "/stripeDirectGetAbonnement", {
        method: "POST",
        body: JSON.stringify({
            subscription_id: subscription_id,
        })
    });
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}

const stripeDirectGetSession = async (session_id) => {
    let response = await fetch(urlFunction + "/stripeRetrieveSession", {
        method: "POST",
        body: JSON.stringify({
            session_id: session_id,
        })
    });
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}


const stripeAbonnement = async (stripe_id) => {
    let user = await getCurrentUser();
    let societe = await getUserFromUid(user.uid)
    let response = await fetch(urlFunction + "/stripeAbonnement", {
        method: "POST",
        body: JSON.stringify({
            stripe_id: stripe_id,
            client_reference_id: user.uid,
            customer_email: user.email,
            customer: societe?.stripe_customer_id,
            success_url: urlSite + "/dashboard/mon-abonnement",
            cancel_url: urlSite + "/dashboard/mon-abonnement"
        })
    });
    if (response.ok) {
        let json = await response.json();
        console.log(json);
        gtag_report_conversion(json);
        //document.location.href = json.url;
    }
}

const gtag_report_conversion = (json) => {
    let url = json.url;
    let amount = json.amount / 100;
    const callback = function () {
        console.log('gtag_report_conversion');
        if (typeof (url) != 'undefined') {
            window.location = url;
        }
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-16499690018/BBaGCNr3_aUZEKKU1bs9',
        'value': amount,
        'currency': 'EUR',
        'event_callback': callback
    });
}

const stripeIsAbonnePersonnalise = async (uid) => {
    let response = await fetch(urlFunction + "/stripeIsAbonne", {
        method: "POST",
        body: JSON.stringify({
            customerId: uid,
        })
    });
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}

const stripeAbonnementPersonnalise = async (montant) => {
    let user = await getCurrentUser();
    let societe = await getUserFromUid(user.uid);
    let response = await fetch(urlFunction + "/stripeAbonnementPersonnalise", {
        method: "POST",
        body: JSON.stringify({
            client_reference_id: user.uid,
            customer_email: user.email,
            customer: societe?.stripe_customer_id,
            success_url: urlSite + "/dashboard/mon-abonnement-personnalise",
            cancel_url: urlSite + "/dashboard/mon-abonnement-personnalise",
            montant: montant
        })
    });
    if (response.ok) {
        let json = await response.json();
        console.log(json);
        document.location.href = json.url;
    }

}

const stripeAnnuleAbonnement = async (subscription_id, endLoading) => {
    let response = await fetch(urlFunction + "/stripeAnnuleAbonnement", {
        method: "POST",
        body: JSON.stringify({
            subscription_id: subscription_id,
        })
    });
    if (response.ok) {
        endLoading();
        /*let json = await response.json();
        document.location.href=json.url;*/
    }

}

const stripeRecupererAbonnement = async (subscription_id, endLoading) => {
    let response = await fetch(urlFunction + "/stripeRecupererAbonnement", {
        method: "POST",
        body: JSON.stringify({
            subscription_id: subscription_id,
        })
    });
    if (response.ok) {
        endLoading();
    }

}


const stripeProrataAbonnementVisualisation = async (subscription, price, customer) => {
    let response = await fetch(urlFunction + "/stripeProrataAbonnementVisualisation", {
        method: "POST",
        body: JSON.stringify({
            subscription: subscription,
            price: price,
            customer: customer
        })
    });
    if (response.ok) {
        let json = await response.json();
        console.log(json);
        return json;
    }

}

const stripeProrataAbonnement = async (subscription, price) => {
    let user = await getCurrentUser();
    let response = await fetch(urlFunction + "/stripeProrataAbonnement", {
        method: "POST",
        body: JSON.stringify({
            subscription: subscription,
            price: price,
            client_reference_id: user.uid,
            success_url: urlSite + "/dashboard/mon-abonnement",
            cancel_url: urlSite + "/dashboard/mon-abonnement"
        })
    });
    if (response.ok) {
        let json = await response.json();
        return json;
    }

}


const getPortalURL = async (customer_id, url) => {
    let response = await fetch(urlFunction + "/getPortalURL", {
        method: "POST",
        body: JSON.stringify({
            customer_id: customer_id,
            url: url
        })
    });
    if (response.ok) {
        let json = await response.json();
        document.location.href = json.url;
    }

}

const getSubscription = async (subscription_id) => {
    let response = await fetch(urlFunction + "/getSubscription", {
        method: "POST",
        body: JSON.stringify({
            subscription_id: subscription_id,
        })
    });
    if (response.ok) {
        let json = await response.json();
        return json;
    }

}

const createPaymentLink = async (price) => {
    let response = await fetch(urlFunction + "/createPaymentLink", {
        method: "POST",
        body: JSON.stringify({
            price: price,
        })
    });
    if (response.ok) {
        let json = await response.json();
        return json;
    } else {
        return {error: ""};
    }
}

const resumePayment = async (invoice_id) => {
    let response = await fetch(urlFunction + "/resumePayment", {
        method: "POST",
        body: JSON.stringify({
            invoice_id: invoice_id,
            return_url: urlSite + "/dashboard/mon-abonnement"
        })
    });
    if (response.ok) {
        let json = await response.json();
        return json;
    } else {
        return {error: ""};
    }
}


const getAbonnementPersonnaliseFromUid = async (uid) => {
    try {
        let response = await fetch(urlFunction + "/getAbonnementPersonnaliseFromUid?uid=" + uid);
        if (response.ok) {
            let json = await response.json();
            return json;
        } else {
            throw new Error('Failed to fetch: ' + response.statusText);
        }
    } catch (error) {
        console.error('Error fetching abonnement personnalisé:', error);
        return {error: error.message};
    }
}

const achatActeUnique = async () => {
    let user = await getCurrentUser();
    let uid = user.uid;
    const response = await fetchWithAuth(urlFunction + "/achatActeUnique", {
        method: "POST",
        body: JSON.stringify({
            success_url: urlSite + "/dashboard/mon-abonnement",
            cancel_url: urlSite + "/dashboard/mon-abonnement"
        }),
    });
    if(response.message==="ok"){
        return response.url;
    }else{
        return false;
    }
}


export {
    achatActeUnique,
    createPaymentLink,
    getSubscription,
    getAbonnement,
    stripeAbonnement,
    stripeAnnuleAbonnement,
    stripeRecupererAbonnement,
    getPortalURL,
    stripeProrataAbonnementVisualisation,
    stripeProrataAbonnement,
    getAbonnementFromPriceId,
    stripeDirectGetAbonnement,
    resumePayment,
    stripeDirectGetSession,
    getAbonnementPersonnaliseFromUid,
    stripeAbonnementPersonnalise,
    stripeIsAbonnePersonnalise
};
