import React from "react";
import Btn from "./Btn";
import {closeModal, Modal} from "./Modal";

export default function ModalNouvelleRequete(){

    return (
        <>
            <Modal id={"abonnement"} titre={"Vous n'êtes pas abonné"}
                   body={"Vous devez être abonné pour pouvoir créer un nouveau dossier."}>
                <Btn texte="Je m'abonne" onClick={() => {
                    document.location.href = "/dashboard/mon-abonnement";
                }}/>
                <Btn texte="Annuler" onClick={() => {
                    closeModal(document.getElementById("abonnement"))
                }}/>
            </Modal>

            <Modal id={"dossier"} titre={"Vous n'avez plus de dossiers"}
                   body={"Vous n'avez plus de dossiers. Si vous voulez créer d'autres dossiers vous devez passer à un abonnement supérieur. Il peut s'écouler quelques minutes avant que vos dossiers mensuels soient crédités."}>
                <Btn texte="Changer mon abonnement" onClick={() => {
                    document.location.href = "/dashboard/mon-abonnement";
                }}/>
                <Btn texte="Annuler" onClick={() => {
                    closeModal(document.getElementById("dossier"))
                }}/>
            </Modal>

            <Modal id={"siren"} titre={"Complétez les informations de votre société."}
                   body={"Vous devez compléter les informations de votre société pour créer un premier dossier."}>
                <Btn texte="Compléter" onClick={() => {
                    document.location.href = "/dashboard/ma-societe";
                }}/>
                <Btn texte="Annuler" onClick={() => {
                    closeModal(document.getElementById("siren"))
                }}/>
            </Modal>
        </>
    );
}
