import React, {useEffect, useState} from "react";
import Btn from "../../components/Btn";
import {Modal, openModal, closeModal} from "../../components/Modal";
import {Helmet} from 'react-helmet';
import {getRequete, updateRequete} from "../../class/Requete";
import {useParams} from "react-router-dom";

export default function Avocat({type}) {
    const [requete, setRequete] = useState([]);
    let {id} = useParams();
    useEffect(() => {


        const getData = async () => {
            let r = await getRequete(id);
            setRequete(r);
        }
        getData();
    }, []);

    const updateRequeteAvocat = async (statut) => {
        await updateRequete(id, {
            statut_avocat: statut,
        })
        let r = await getRequete(id);
        setRequete(r);
        openModal(document.getElementById("modal_contact_avocat"))
    }


    return (
        <div className={"container-fluid"}>
            <Helmet>
                <title>Avocats facturesimpayees.com</title>
                <meta name="description"
                      content="Suivi de votre dossier par un avocat sur facturesimpayees.com."/>
                <meta name="keywords"
                      content="informations société, factures impayées, plateforme, fonctionnalités, facturesimpayees.com"/>
            </Helmet>
            <h2 className={"dashboard-title"}>Confier votre dossier à un avocat</h2>
            <div className={"align-items-center"}>
                <p>Le tribunal vous informe que votre débiteur a contesté votre demande et vous aimeriez soumettre votre
                    dossier à un avocat?</p>
                <p> Pour cela il vous suffit de cliquer sur le bouton ci-dessous. En effectuant cette action vous
                    acceptez que votre dossier soit consulté par un avocat qui prendra contact avec vous pour vous
                    proposer ses services.</p>
                <p>Vous pourrez alors décidé de lui confier ou non votre affaire. Attention, si vous utilisez ce service
                    il convient de vous assurer préalablement que la date d'audience est suffisamment éloignée (10 jours
                    ouvrables minimum) pour permettre à un avocat de traiter votre demande.</p>


                {requete.statut_avocat === "ordonnance envoyee" ?
                    <div className={"alert alert-success"}>Votre dossier a été transmis, un avocat prendra contact
                        avec vous dans les 72 heures ouvrables.</div> : null}
                {requete.statut_avocat === "recouvrement termine" ?
                    <div className={"alert alert-success"}>Votre dossier a été pris en charge.</div> : null}
                {requete.statut_avocat === "ordonnance annulee" ?
                    <div className={"alert alert-danger"}>Votre prise en charge de dossier a été annulée.</div> : null}
                <div className={"d-flex justify-content-center gap-3"}>
                    {!requete.statut_avocat ?
                        <Btn texte={"Prendre contact avec un avocat"} iconeDroite={"fleche"} onClick={async () => {
                            await updateRequeteAvocat("ordonnance envoyee");
                        }
                        }/> : null}
                    {requete.statut_avocat!=="ordonnance annulee" ?
                    <Btn texte={"Je ne cherche plus d'avocat"} iconeDroite={"fleche"} onClick={async () => {
                        await updateRequeteAvocat("ordonnance annulee");
                    }
                    }/>:null}
                </div>

            </div>
            <Modal id={"modal_contact_avocat"} titre={"Votre dossier a été transmis"}
                   body={"Les informations de votre dossier ont été transmises, un avocat prendra contact avec vous dans les 72 heures ouvrables."}>
                <Btn texte="OK" onClick={() => {
                    closeModal(document.getElementById("modal_contact_avocat"))
                }}/>
            </Modal>
        </div>

    );
}
